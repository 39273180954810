$(window).on 'load', ->
	#
	# Flexslider Sidebar Setup
	#
	$('.sidebar-slider').flexslider
		controlNav: false
		smoothHeight: true
	# Gallery Flexslider Setup
	$('.gallery-slider-carousel').flexslider
		animation: "slide"
		controlNav: false
		animationLoop: false
		slideshow: false
		itemWidth: 150
		itemMargin: 5
		asNavFor: '.gallery-slider'
	$('.gallery-slider').flexslider
		animation: "slide"
		controlNav: false
		animationLoop: false
		slideshow: false
		smoothHeight: true
		sync: ".gallery-slider-carousel"
	#
	# Flexslider for Page Headers
	#
	$('.header-flexslider').flexslider
		controlNav: false
		directionNav: false
		smoothHeight: true
		slideshowSpeed: 4000
		animationSpeed: 1500
	$('.ad-flexslider').flexslider
		controlNav: false
		directionNav: false
		smoothHeight: true
		slideshowSpeed: 4000
		animationSpeed: 1500
	#
	# Signup Toggle
	#
	$('.signup').click ->
		$('.signup-expanded').slideToggle()

$ ->
	$document = $(document)
	$window = $(window)


	#
	# Utility function: Balance height of child elements
	#
	balance_height_of_children = ($parent) ->
		unless $parent.length > 0
			return false
		tallestElHeight = 0
		$children = $parent.children()
		# Reset heights to auto
		$children.each ->
			$(this).css "height", "auto"
		# Calculate the height of the tallest submenu
		$children.each ->
			tallestElHeight = if tallestElHeight > $(this).outerHeight() then tallestElHeight else $(this).outerHeight()
		tallestElHeight = Math.ceil tallestElHeight
		$children.each ->
			$(this).css "height", "#{tallestElHeight}px"
		$window.on 'load', -> balance_height_of_children($parent)
			# .resize -> balance_height_of_children($parent)


	# balance_height_of_children $('#menu-primary-header-menu')
	balance_height_of_children $('.featured-stores-list')

	#
	# Append Secondary Menu to Primary for Mobile View
	#
	$('.nav__secondary li').each ->
		$('.nav__main li:last-child').after( $(this).clone().addClass('mobile') )


	#
	# Store Directory
	#
	$('.store_categories--list .store-toggle').on 'click', (event) ->
		event.preventDefault()
		$this = $(this)
		$this.toggleClass 'active'

		# Show map tooltip
		mapEl = $this.next().find('.im-element')
		mapId = mapEl.attr('id')
		mapStart = mapEl.data('start')
		window.$lastclickedanchorID = mapId
		window.location.hash = '0'
		window.location.hash = mapStart

	#
	# Store Directory Tabs
	#
	$ ->
		hash = undefined
		hash = window.location.hash
		hash and $("ul.tabbed-nav a[href=\"" + hash + "\"]").tab("show")
		$(".tabbed-nav a").click (e) ->
			$(this).tab "show"

	#
	# Set First Tab for Promotions Page
	#
	$ ->
		hash = undefined
		hash = window.location.hash
		hash and $(".promotions.tabbed-nav a[href=\"" + hash + "\"]").tab("show")
	#$('.promotions.tabbed-nav a:first').tab('show');

	#
	# Coupon Popup
	#
	# $(".coupon-popup").magnificPopup
	# 	type: "image"
	# 	image:
	# 		markup: "<div class='mfp-figure'>" + "<div class='mfp-close'></div>" + "<div class='mfp-img'></div>" + "<div class='mfp-bottom-bar'>" + "</div>" + "</div>"
	# 	closeOnContentClick: true
	# 	closeBtnInside: true
	$(".coupon-popup").on "click", (event)->
		event.preventDefault()
		$click_check = $(this).data('clicked')
		if $click_check == 'yes'

		else
			$coupon = $(this).children("img")
			$.print($coupon)
			$(this).data('clicked', 'yes')
			$(this).children('.disabled').css('opacity', 1)
			$coupon_number = $(this).data('coupon')
			localStorage.setItem $coupon_number, 'used'

	$('.coupon-popup').each ->
		$coupon_use_check = $(this).data('coupon')
		$used = localStorage.getItem($coupon_use_check)
		if $used == 'used'
			$(this).data('clicked', 'yes')
			$(this).children('.disabled').css('opacity', 1)


	#
	# ACF Google Maps Field
	#
	# This function will render a Google Map onto the selected jQuery element
	render_map = ($el) ->
		# var
		$markers = $el.find('.marker')

		# vars
		args = {
			zoom		: 15
			center		: new google.maps.LatLng(0, 0)
			mapTypeId	: google.maps.MapTypeId.ROADMAP
			disableDefaultUI : true
			styles: [
				{
					"featureType": "administrative",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#70090a"
						}
					]
				},
				{
					"featureType": "landscape",
					"elementType": "all",
					"stylers": [
						{
							"color": "#f2f2f2"
						}
					]
				},
				{
					"featureType": "poi",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "all",
					"stylers": [
						{
							"saturation": -100
						},
						{
							"lightness": 45
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "simplified"
						}
					]
				},
				{
					"featureType": "road.arterial",
					"elementType": "labels.icon",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "transit",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "all",
					"stylers": [
						{
							"color": "#e8e8df"
						},
						{
							"visibility": "on"
						}
					]
				}
			]
		}

		# create map
		map = new google.maps.Map( $el[0], args)

		# add a markers reference
		map.markers = []

		# add markers
		$markers.each () ->
			add_marker( $(this), map )

		# center map
		center_map( map )

	# This function will add a marker to the selected Google Map
	add_marker = ($marker, map) ->
		# var
		latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') )

		# create marker
		marker = new google.maps.Marker({
			position	: latlng
			map			: map
		})

		# add to array
		map.markers.push( marker )

		# if marker contains HTML, add it to an infoWindow
		if $marker.html()
			# create info window
			infowindow = new google.maps.InfoWindow({
				content		: $marker.html()
				maxWidth: '340'
			})

			infowindow.open( map, marker )
			# show info window when marker is clicked
			google.maps.event.addListener marker, 'click', () ->
				infowindow.open( map, marker )
				map.setCenter(marker.getPosition())

	# This function will center the map, showing all markers attached to this map
	center_map = (map) ->
		bounds = new google.maps.LatLngBounds()

		$.each map.markers, (i, marker) ->
			latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() )
			bounds.extend( latlng )

		if map.markers.length is 1
			map.setCenter( bounds.getCenter() )
			map.setZoom( map.zoom )
		else
			map.fitBounds( bounds )

	# This function will render each map (typically run on document ready)
	$('.acf-map').each ->
		render_map( $(this) )


	#
	# Open map when on Contact page
	#
	if $('body.pagename-contact').length > 0
		$window.on 'load', ->
			$('#sidebar-open-google-maps').click()

	#
	# Enquire
	#
	enquire.register("screen and (min-width:1000px)", {
		match: ->
			# console.log "matched mobile"
			$('.nav-wrapper').collapse('show')
		unmatch: ->
			# console.log "unmatched mobile"
			$('.nav-wrapper').collapse('hide')
		setup: ->
			$('.nav-wrapper').removeClass 'in'
	})
